import type { EnrichedOrderLine } from '~/types';

/**
 * Checks if there is a quantity mismatch for the given order line.
 * A quantity mismatch occurs if the line quantity is less than the lowest quantity,
 * greater than the highest quantity, or not a multiple of the specified quantity.
 */
export function hasQtyMismatch(line: EnrichedOrderLine) {
    if (
        !line.mdh.salesOrderSettings ||
        line.mdh.salesOrderSettings.length === 0
    ) {
        return false;
    }

    const { lowestQty, highestQty, multipleQty } =
        line.mdh.salesOrderSettings[0];

    if (lowestQty && line.Quantity < lowestQty) {
        return true;
    }

    if (highestQty && line.Quantity > highestQty) {
        return true;
    }

    if (multipleQty && line.Quantity % multipleQty !== 0) {
        return true;
    }

    return false;
}

import type { ShippingOffer } from '~/types';
import { hasQtyMismatch } from '~/functions/hasQtyMismatch';
import type { DropPoint } from '~/types/webshipper/drop-points';

export async function useShippingConfig() {
    const token = useToken();

    const { enrichedOrderlines } = await useActiveOrder();
    const shippingMethod = useState<ShippingOffer | undefined>(
        'shippingMethod',
    );
    const dropPoint = useState<DropPoint | undefined>('dropPoint');

    // OD-48 and OD-44 Business logic:
    const idOfMainSiteWebshipperOffer = parseInt(token.main_wh_rate_id);
    const isShippingFromMainWarehouse = computed(() => {
        return (
            idOfMainSiteWebshipperOffer &&
            shippingMethod.value?.shipping_rate_id ===
                idOfMainSiteWebshipperOffer
        );
    });

    // disable proceed to the payment (place the order) if not met
    const hasIssuesWithQuantities = computed(() => {
        return (
            isShippingFromMainWarehouse.value &&
            // filter each order line to ensure qty's are met if main site is selected
            enrichedOrderlines.value.some(hasQtyMismatch)
        );
    });

    return {
        dropPoint,
        shippingMethod,
        idOfMainSiteWebshipperOffer,
        isShippingFromMainWarehouse,
        hasIssuesWithQuantities,
    };
}
